import { FormEvent, useState, useMemo } from 'react';
import { Input, FormField, Button } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { Eye, EyeSlash } from '@gravity-ui/icons';
import { ApolloError } from '@apollo/client';
import {
  ButtonStyle, FooterLink, LinkButton, FormStyle, Link, Title, Wrapper,
} from './styles';

interface LoginFormProps {
  signIn?: (email: string, password: string) => void,
  error?: ApolloError
  loading?: boolean
}

export const LoginForm = ({ signIn, error, loading }: LoginFormProps) => {
  const { t } = useTranslation('login');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isShowPass, setIsShowPass] = useState(false);
  const [passError, setPassError] = useState('');
  const [mailError, setMailError] = useState('');

  useMemo(() => {
    if (error && error.message.includes('password', 1)) {
      setPassError(error.message);
      return true;
    }
    if (error && error.message.includes('email', 1)) {
      setMailError(error.message);
      return true;
    }
    return false;
  }, [error]);

  const isFormValid = useMemo(
    () =>
      (email && password),
    [email, password],
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    signIn && signIn(email, password);
  };

  const updateEmail = (value: string) => {
    setMailError('');
    setEmail(value);
  };

  const updatePassword = (value: string) => {
    setPassError('');
    setPassword(value);
  };

  return (
    <Wrapper>
      <Title>
        {t('title')}
      </Title>

      <FormStyle onSubmit={handleSubmit}>
        <FormField
          name="email"
          label={t('label.email')}
        >
          <Input
            type="text"
            name="email"
            status={!mailError ? 'default' : 'error'}
            message={mailError ? t<string>('errorMessage.EAC_USER_NOT_FOUND', { name: email }) : undefined}
            required
            onChange={updateEmail}
          />
        </FormField>

        <FormField
          name="password"
          label={t('label.password')}
          buttons={<LinkButton href="/password-recovery">{t('links.forgotPass')}</LinkButton>}
        >
          <Input
            type={isShowPass ? 'text' : 'password'}
            name="password"
            status={!passError ? 'default' : 'error'}
            message={passError ? t<string>('errorMessage.EAC_INVALID_PASSWORD') : undefined}
            required
            onChange={updatePassword}
          >
            <Button
              view="ghost"
              size="small"
              iconLeft={isShowPass ? <EyeSlash /> : <Eye />}
              onClick={() => setIsShowPass(!isShowPass)}
            />
          </Input>
        </FormField>

        <ButtonStyle
          type="submit"
          disabled={!isFormValid}
          loading={loading}
        >
          {t('button')}
        </ButtonStyle>
      </FormStyle>

      <FooterLink>
        {t('accountNotExists')}<Link href="/register">{t('links.sighUp')}</Link>
      </FooterLink>
    </Wrapper>
  );
};
